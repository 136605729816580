import BotIcon from "./icons/bot";
import { cn } from "../utils/cn";
import CloseIcon from "./icons/close";
import { useEffect, useState } from "react";
import { remark } from "remark";
import html from "remark-html";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export default function Widget({ isOpen, siteId, onClick }) {
  const [isStreaming, setIsStreaming] = useState(false);
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "Welcome to PubTrawlr AI. How can I help you today?",
    },
  ]);

  const sessionId = window.crypto.randomUUID();

  const streamResponse = async (question, last6Messages) => {
    try {
      setIsStreaming(true);
      const raw = JSON.stringify({
        site_id: siteId,
        question,
        session_id: sessionId,
        chat_history: last6Messages,
      });
      await fetchEventSource(`${process.env.REACT_APP_CHAT_SERVER_URI}/chat`, {
        method: "POST",
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: raw,
        onmessage(event) {
          const parsedData = JSON.parse(event.data);
          if (parsedData.end || parsedData.error) {
            setIsStreaming(false);
            return;
          }
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              role: "assistant",
              content: parsedData.answer,
              sources: parsedData.meta_data,
            },
          ]);
        },
        onerror() {
          setIsStreaming(false);
        },
      });
    } catch {
      setIsStreaming(false);
    }
  };

  const sendMessage = async () => {
    const query = document.getElementById("pubtrawlr-ai-chat-input").value;
    if (query === "") return;
    const last6Messages = messages.slice(-6);
    setMessages((prev) => [
      ...prev,
      {
        role: "user",
        content: query,
      },
    ]);
    setMessages((prev) => [
      ...prev,
      {
        role: "assistant",
        content: "",
      },
    ]);
    document.getElementById("pubtrawlr-ai-chat-input").value = "";
    await streamResponse(query, last6Messages);
  };

  useEffect(() => {
    if (isOpen) {
      // focus on input
      const input = document.getElementById("pubtrawlr-ai-chat-input");
      input.focus();
    }
  }, [isOpen]);

  return (
    <div
      style={{
        zIndex: 1001,
      }}
      className={cn(
        "pw-fixed pw-flex pw-flex-col pw-bottom-0 pw-right-0 pw-rounded-lg pw-shadow-sm pw-shadow-black pw-w-full lg:pw-w-1/2 pw-h-lvh",
        {
          "pw-hidden": !isOpen,
        },
      )}
    >
      <div className="pw-bg-slate-950 pw-font-bold pw-text-white pw-rounded-tl-lg pw-p-2 pw-flex pw-items-center">
        <div className="pw-flex pw-items-center">
          <BotIcon className="pw-size-8 pw-mr-1" />
          PubTrawlr AI
        </div>
        <button
          className="pw-ml-auto hover:pw-cursor-pointer pw-bg-slate-950 pw-border-none"
          onClick={onClick}
        >
          <CloseIcon className="pw-size-8" />
        </button>
      </div>
      <div className="pw-h-full pw-bg-slate-100 pw-p-2 pw-overflow-auto pw-flex pw-flex-col pw-gap-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={cn("pw-p-1 pw-rounded-lg pw-flex", {
              "pw-justify-end": message.role === "user",
              "pw-justify-start": message.role === "assistant",
            })}
          >
            <div
              className={cn("pw-flex pw-items-center pw-p-2 pw-rounded-lg", {
                "pw-bg-slate-200 pw-justify-end": message.role === "user",
                "pw-bg-slate-950 pw-text-white": message.role === "assistant",
              })}
            >
              {message.role === "user" ? (
                <div>{message.content}</div>
              ) : (
                <div className="pubtrawlr-markdown pw-rounded-2xl pw-ai-chat-bubble">
                  <div
                    className="pw-text-left"
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(html)
                        .processSync(message.content)
                        .toString()
                        .replace(
                          /<a /g,
                          '<a target="_blank" rel="noopener noreferrer" ',
                        ),
                    }}
                  />
                  {isStreaming && index === messages.length - 1 &&  (
                    <div class="pw-typing">
                      <div class="pw-dot"></div>
                      <div class="pw-dot"></div>
                      <div class="pw-dot"></div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="pw-min-h-12 pw-relative" id="pubtrawlr-ai-chat-input-box">
        <textarea
          className="pw-w-full pw-h-full pw-p-2 pw-bg-slate-100 pw-rounded-bl-lg pw-resize-none pw-outline-none pw-ring-0"
          id="pubtrawlr-ai-chat-input"
          maxLength={1000}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !isStreaming) {
              e.preventDefault(); // Prevent newline in textarea
              sendMessage();
            }
          }}
        />
      </div>
    </div>
  );
}
