import ChatIcon from "./icons/chat";

export default function WidgetOpenButton({ onClick, isOpen }) {
  return isOpen ? (
    <></>
  ) : (
    <button
      className="
      pw-fixed
      hover:pw-cursor-pointer
      pw-rounded-full
      pw-bottom-4
      pw-right-4
      pw-border-none
      pw-bg-none
      pw-shadow-sm
      pw-flex pw-justify-center pw-items-center
      pw-p-2
      pw-shadow-slate-950"
      onClick={onClick}
      style={{
        zIndex: 1001,
      }}
    >
      <ChatIcon className="pw-size-8" />
    </button>
  );
}
